import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ImageUploader from "../../misc/ImageUploader";
import ImageZoom from "../../misc/ImageWithZoom";
import Save from "../../../../../icons/save";
import axios from "axios";
import AWS from "aws-sdk";

const CarouselModal = () => {
  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });

  const [pictureModal, setPictureModal] = useState<File>();

  const [pictureModalPreview, setPictureModalPreview] = useState<string>("");

  const [pictureModalURL, setPictureModalURL] = useState<string>("");

  const handleCarouselModal = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    Swal.fire({
      title: "Uploading...",
      text: "Please wait while we upload the images.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      if (pictureModal) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureModal?.name}`,
          Body: pictureModal,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=modalPictureOne`,
          {
            image: `publicidad/${pictureModal?.name}`,
            external_url: pictureModalURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      Swal.fire({
        icon: "success",
        title: "Images uploaded successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred during the upload.",
      });
      console.error("Error uploading file:", error);
    }
  };

  const getModal = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/imageModal`
      );

      let tempImageOne = response.data.filter(
        (image: any) => image.placement === "modalPictureOne"
      );

      if (tempImageOne) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageOne[0].link}`,
          Expires: 60 * 60,
        };

        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            setPictureModalPreview(url);
            setPictureModalURL(tempImageOne[0].external_url);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getModal();
  }, []);

  return (
    <div className="p-2 border border-slate-200 rounded m-2">
      <p>Moda</p>
      {pictureModalPreview && (
        <img
          src={pictureModalPreview}
          alt="Current modal"
          className="w-32 h-32 object-cover rounded"
        />
      )}
      <form action="" className="gap-2 my-4" onSubmit={handleCarouselModal}>
        <div className="flex">
          <h1>Seccion Modal</h1>
          <button>
            <Save size={26} />
          </button>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col gap-2">
            <p>Foto #1</p>
            <input
              value={pictureModalURL}
              onChange={(e) => setPictureModalURL(e.target.value)}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureModal}
              inputId="CarouselModalPictureOne"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureModalPreview} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CarouselModal;
