import { useEffect, useState } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import GifDisplay from "./GIFDisplay";

const MenuImage = () => {
  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });

  const [modalImage, setModalImage] = useState<string | null>(null);

  const getMenuImage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/videoTwo`
      );
      if (response.data.length > 0) {
        const image = response.data[0];
        const paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: image.link,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            setModalImage(url);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("getMenuImage");
    getMenuImage();
  }, []);

  if (!modalImage) {
    return null;
  }

  return <GifDisplay gifUrl={modalImage} gifId={"menuImage"} />;
};

export default MenuImage;
