import PremioIcon from "../../../../icons/menu/premio";
import RetoIcon from "../../../../icons/menu/reto";
import PedidoIcon from "../../../../icons/menu/pedido";
import FarmaciaIcon from "../../../../icons/menu/farmacia";
import RetoComprobanteIcon from "../../../../icons/menu/reto_comprobante";
import VentaComprobanteIcon from "../../../../icons/menu/venta_comprobante";
import UsuarioIcon from "../../../../icons/menu/usuario";
import ProductoIcon from "../../../../icons/menu/producto";
import ReporteIcon from "../../../../icons/menu/reporte";
import MenuIcon from "../../../../icons/menu";
import ExitIcon from "../../../../icons/menu/exit";
import PublicidadIcon from "../../../../icons/menu/publicidad";
import PerfilIcon from "../../../../icons/menu/perfil";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import checkRoles from "../misc/checkRoles";

interface Props {
  view: string;
  setView: React.Dispatch<React.SetStateAction<string>>;
}

const Menu = ({ view, setView }: Props) => {
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  console.log(checkRoles(["Admin"]));
  return (
    <div className="p-2 md:h-screen flex flex-col">
      <div className="flex">
        <img
          src={require("../../../../icons/Logo-CefaPlus.png")}
          alt=""
          className="max-w-[200px] h-auto my-2"
        />

        <div className="grow" />
        <button onClick={() => setState(!state)} className="md:hidden inline">
          <MenuIcon />
        </button>
      </div>
      {state ? (
        <div className="flex flex-col gap-2 ">
          {checkRoles(["Admin", "Viewer"]) && (
            <button
              className={`menu-item ${view === "Premios" ? "active" : ""}`}
              onClick={() => setView("Premios")}
            >
              <PremioIcon />
              <p>Premios</p>
            </button>
          )}
          {checkRoles(["Admin"]) && (
            <button
              className={`menu-item ${view === "Retos" ? "active" : ""}`}
              onClick={() => setView("Retos")}
            >
              <RetoIcon />
              <p>Retos</p>
            </button>
          )}
          {checkRoles(["Admin"]) && (
            <button
              className={`menu-item ${view === "Pedidos" ? "active" : ""}`}
              onClick={() => setView("Pedidos")}
            >
              <PedidoIcon />
              <p>Pedidos</p>
            </button>
          )}
          {checkRoles(["Admin"]) && (
            <button
              className={`menu-item ${view === "Usuarios" ? "active" : ""}`}
              onClick={() => setView("Usuarios")}
            >
              <UsuarioIcon />
              <p>Usuario</p>
            </button>
          )}
          {checkRoles(["Admin"]) && (
            <button
              className={`menu-item ${view === "Farmacias" ? "active" : ""}`}
              onClick={() => setView("Farmacias")}
            >
              <FarmaciaIcon />
              <p>Farmacias</p>
            </button>
          )}
          {checkRoles(["Admin"]) && (
            <button
              className={`menu-item ${
                view === "RetoComprobantes" ? "active" : ""
              }`}
              onClick={() => setView("RetoComprobantes")}
            >
              <RetoComprobanteIcon />
              <p>Reto comprobantes</p>
            </button>
          )}
          {checkRoles(["Admin"]) && (
            <button
              className={`menu-item ${
                view === "VentaComprobantes" ? "active" : ""
              }`}
              onClick={() => setView("VentaComprobantes")}
            >
              <VentaComprobanteIcon />
              <p>Venta comprobantes</p>
            </button>
          )}
          {checkRoles(["Admin"]) && (
            <button
              className={`menu-item ${view === "Productos" ? "active" : ""}`}
              onClick={() => setView("Productos")}
            >
              <ProductoIcon />
              <p>Productos</p>
            </button>
          )}
          {checkRoles(["Admin"]) && (
            <button
              className={`menu-item ${view === "Publicidad" ? "active" : ""}`}
              onClick={() => setView("Publicidad")}
            >
              <PublicidadIcon />
              <p>Publicidad</p>
            </button>
          )}
          {checkRoles(["Admin", "Viewer"]) && (
            <button
              className={`menu-item ${view === "Reportes" ? "active" : ""}`}
              onClick={() => setView("Reportes")}
            >
              <ReporteIcon />
              <p>Reportes</p>
            </button>
          )}
          <button
            className={`menu-item ${view === "Perfil" ? "active" : ""}`}
            onClick={() => setView("Perfil")}
          >
            <PerfilIcon />
            <p>Perfil</p>
          </button>
          <button
            className={`menu-item ${view === "Salir" ? "active" : ""}`}
            onClick={() => sessionStorage.clear()}
          >
            <ExitIcon />
            <p>Salir</p>
          </button>
        </div>
      ) : null}
      <div className="flex-col hidden gap-2 md:flex md:grow">
        {checkRoles(["Admin", "Viewer"]) && (
          <button
            className={`menu-item ${view === "Premios" ? "active" : ""}`}
            onClick={() => setView("Premios")}
          >
            <PremioIcon />
            <p>Premios</p>
          </button>
        )}
        {checkRoles(["Admin"]) && (
          <button
            className={`menu-item ${view === "Retos" ? "active" : ""}`}
            onClick={() => setView("Retos")}
          >
            <RetoIcon />
            <p>Retos</p>
          </button>
        )}
        {checkRoles(["Admin", "Viewer"]) && (
          <button
            className={`menu-item ${view === "Pedidos" ? "active" : ""}`}
            onClick={() => setView("Pedidos")}
          >
            <PedidoIcon />
            <p>Pedidos</p>
          </button>
        )}
        {checkRoles(["Admin"]) && (
          <button
            className={`menu-item ${view === "Usuarios" ? "active" : ""}`}
            onClick={() => setView("Usuarios")}
          >
            <UsuarioIcon />
            <p>Usuario</p>
          </button>
        )}
        {checkRoles(["Admin"]) && (
          <button
            className={`menu-item ${view === "Farmacias" ? "active" : ""}`}
            onClick={() => setView("Farmacias")}
          >
            <FarmaciaIcon />
            <p>Farmacias</p>
          </button>
        )}
        {checkRoles(["Admin"]) && (
          <button
            className={`menu-item ${
              view === "RetoComprobantes" ? "active" : ""
            }`}
            onClick={() => setView("RetoComprobantes")}
          >
            <RetoComprobanteIcon />
            <p>Reto comprobantes</p>
          </button>
        )}
        {checkRoles(["Admin"]) && (
          <button
            className={`menu-item ${
              view === "VentaComprobantes" ? "active" : ""
            }`}
            onClick={() => setView("VentaComprobantes")}
          >
            <VentaComprobanteIcon />
            <p>Venta comprobantes</p>
          </button>
        )}
        {checkRoles(["Admin"]) && (
          <button
            className={`menu-item ${view === "Productos" ? "active" : ""}`}
            onClick={() => setView("Productos")}
          >
            <ProductoIcon />
            <p>Productos</p>
          </button>
        )}
        {checkRoles(["Admin"]) && (
          <button
            className={`menu-item ${view === "Publicidad" ? "active" : ""}`}
            onClick={() => setView("Publicidad")}
          >
            <PublicidadIcon />
            <p>Publicidad</p>
          </button>
        )}
        {checkRoles(["Admin", "Viewer"]) && (
          <button
            className={`menu-item ${view === "Reportes" ? "active" : ""}`}
            onClick={() => setView("Reportes")}
          >
            <ReporteIcon />
            <p>Reportes</p>
          </button>
        )}
        <div className="grow"></div>
        <button
          className={`menu-item ${view === "Perfil" ? "active" : ""}`}
          onClick={() => setView("Perfil")}
        >
          <PerfilIcon />
          <p>Perfil</p>
        </button>
        <button
          className="menu-item"
          onClick={() => {
            sessionStorage.clear();
            navigate("/");
          }}
        >
          <ExitIcon />
          <p>Salir</p>
        </button>
      </div>
    </div>
  );
};

export default Menu;
