import AWS from "aws-sdk";
import { useEffect, useState } from "react";
import ApproveIcon from "../../../../../icons/approved";
import Reject from "../../../../../icons/reject";
import axios from "axios";
import Close from "../../../../../icons/close";
import { formatToMMDDYYYY } from "../../../../../utils/dates";
import ImageZoomModal from "../../misc/ImageWithZoom";
import Swal from "sweetalert2";
import EditList from "./EditList";

interface VentaComprobante {
  venta_comprobante_id: number;
  fecha_creacion: string;
  cantidad: number;
  imagen: string;
  estado: string;
  numero_factura: string;
  usuario: string;
}

interface Products {
  producto_id: number;
  nombre: string;
  precio: number;
  cantidad: number;
  puntos: number;
  unidad: string;
  precio_usado: string;
}

interface Props {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  ventaComprobanteView: boolean;
  setVentaComprobanteView: React.Dispatch<React.SetStateAction<boolean>>;
  ventaComprobanteID: number;
  setVentaComprobanteViewID: React.Dispatch<React.SetStateAction<number>>;
}

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

const View = ({
  counter,
  setCounter,
  ventaComprobanteID,
  setVentaComprobanteViewID,
  ventaComprobanteView,
  setVentaComprobanteView,
}: Props) => {
  const [ventaComprobante, setVentaComprobante] = useState<VentaComprobante>();
  const [img, setImg] = useState("");
  const [imageType, setImageType] = useState(false);
  const [products, setProducts] = useState<Products[]>([]);
  const [view, setView] = useState(false);

  const approveComprobante = async () => {
    try {
      Swal.fire({
        title: "Espere...",
        text: "Accion en proceso",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await axios.put(
        `${
          process.env.REACT_APP_API_URL
        }/api/venta_comprobante/approve/${ventaComprobanteID}/${sessionStorage.getItem(
          "user_id"
        )}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCounter(counter + 1);
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/venta_comprobante/nextComprobante`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setVentaComprobanteViewID(result.data.venta_comprobante_id);

      Swal.fire({
        icon: "success",
        title: "Comprobante aprobado",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error: any) {
      setVentaComprobanteView(!ventaComprobanteView);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
      console.log(error);
    }
  };

  const rejectComprobante = async () => {
    try {
      await axios.put(
        `${
          process.env.REACT_APP_API_URL
        }/api/venta_comprobante/reject/${ventaComprobanteID}/${sessionStorage.getItem(
          "user_id"
        )}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setCounter(counter + 1);
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/venta_comprobante/nextComprobante`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setVentaComprobanteViewID(result.data.venta_comprobante_id);
      Swal.fire({
        icon: "success",
        title: "Comprobante rechazado",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error: any) {
      setVentaComprobanteView(!ventaComprobanteView);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
      console.log(error);
    }
  };

  const getComprobante = async (ventaComprobanteID: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/venta_comprobante/${ventaComprobanteID}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setVentaComprobante(response.data.results[0]);
      setProducts(response.data.products);

      if (isPDF(response.data.results[0].imagen)) {
        setImageType(true);
      }
      const paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: response.data.results[0].imagen,
        Expires: 60 * 60,
      };

      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setImg(url);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const isPDF = (url: any) => {
    return url.toLowerCase().endsWith(".pdf");
  };

  useEffect(() => {
    if (ventaComprobanteID !== 0) {
      setImageType(false);
      getComprobante(ventaComprobanteID);
    }
  }, [ventaComprobanteID, view]);
  return (
    <>
      {ventaComprobanteView ? (
        <div className={`${ventaComprobanteView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white w-screen md:w-1/3 md:min-w-0 min-w-full overflow-auto p-4 ${
              ventaComprobanteView ? "animate-slide-in" : "animate-slide-out"
            }`}
          >
            <div className="flex items-center w-full ">
              <p className="text-lg">
                <strong>Comprobante De Venta</strong>
              </p>
              <div className="grow" />
              <button
                onClick={() => {
                  setView(false);
                  setVentaComprobanteView(!ventaComprobanteView);
                }}
              >
                <Close />
              </button>
            </div>
            <div className="flex flex-col gap-3 overflow-y-scroll">
              <div className="flex flex-col gap-3">
                <div className="flex flex-row gap-2 mt-4">
                  {ventaComprobante?.estado === "Aprobado" ||
                  ventaComprobante?.estado === "Rechazado" ? null : (
                    <>
                      <button
                        onClick={approveComprobante}
                        type="button"
                        className="cefa-button"
                      >
                        <ApproveIcon />
                      </button>
                      <button
                        onClick={rejectComprobante}
                        type="button"
                        className="reject-button"
                      >
                        <Reject />
                      </button>
                    </>
                  )}
                </div>
                <div className="m-auto w-fit">
                  {imageType ? (
                    <a href={img} download>
                      <button className="cefa-button" type="button">
                        Descargar PDF
                      </button>
                    </a>
                  ) : (
                    <ImageZoomModal imageUrl={img} />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <p>
                      <strong>Numero de factura:</strong>
                    </p>
                    <p>{ventaComprobante?.numero_factura}</p>
                  </div>
                  <div className="flex gap-2">
                    <p>
                      <strong>Fecha de creacion:</strong>
                    </p>
                    <p>{formatToMMDDYYYY(ventaComprobante?.fecha_creacion)}</p>
                  </div>
                  <div className="flex gap-2">
                    <p>
                      <strong>Estado:</strong>
                    </p>
                    <div>{ventaComprobante?.estado}</div>
                  </div>
                  <div className="text-lg">
                    <strong>Informacion Del Colaborador</strong>
                  </div>
                  <div className="flex gap-2">
                    <p>
                      <strong>Nombre:</strong>
                    </p>
                    <p>{ventaComprobante?.usuario}</p>
                  </div>
                </div>

                <div
                  className="overflow-auto bg-white pb-8 md:pb-0"
                  style={{ maxHeight: "calc(65vh)" }}
                >
                  <table className="w-full">
                    <thead className="bg-gray-50 top-0 sticky">
                      <tr className="table-head">
                        <th className="text-left">Nombre</th>
                        <th className="text-left">Cantidad</th>
                        <th className="text-left">Unidad</th>
                        <th className="text-left">Precio Usado</th>
                        <th className="text-left">Puntos</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {products.length === 0 ? (
                        <tr className="hover:bg-slate-300">
                          <td className="text-center" colSpan={6}>
                            No hay productos disponibles
                          </td>
                        </tr>
                      ) : (
                        products.map((comprobante) => (
                          <tr
                            className="hover:bg-slate-100 table-body"
                            key={comprobante.producto_id}
                          >
                            <td className="text-left">{comprobante.nombre}</td>
                            <td className="text-left">
                              {comprobante.cantidad}
                            </td>
                            <td className="text-left">{comprobante.unidad}</td>
                            <td className="text-left">
                              {comprobante.precio_usado}
                            </td>
                            <td className="text-left">{comprobante.puntos}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <hr />
                <button className="cefa-button" onClick={() => setView(!view)}>
                  Editar
                </button>
              </div>
              <EditList
                productListProps={products}
                view={view}
                setView={setView}
                ventaComprobanteID={ventaComprobanteID}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default View;
