import axios from "axios";
import { useEffect, useState } from "react";
import Status from "../../../../../icons/status";

interface Producto {
  farmacia_producto_id: number;
  nombre: string;
  cantidad: number;
  estado: number;
  articulo: string;
}

interface Props {
  farmaciaViewID: number;
  counterInventory: number;
  setCounterInventory: React.Dispatch<React.SetStateAction<number>>;
}

const FarmaciaProductosView = ({
  farmaciaViewID,
  counterInventory,
  setCounterInventory,
}: Props) => {
  const [productos, setProductos] = useState<Producto[]>([]);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredProductos = productos.filter((producto) => {
    return producto.nombre.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const getProductos = async (farmaciaViewID: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/farmacias/productos/${farmaciaViewID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": sessionStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      setProductos(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductos(farmaciaViewID);
  }, [counterInventory]);

  return (
    <div>
      <input
        type="text"
        placeholder="Buscar"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="main-input my-4"
      />
      <div
        className=" bg-white pb-8 md:pb-0 overflow-auto"
        style={{ maxHeight: "calc(90vh)" }}
      >
        <table className="w-full overflow-auto">
          <thead className="bg-gray-50 top-0 sticky">
            <tr className="table-head text-left">
              <th>Articulo</th>
              <th>Nombre</th>
              <th className="text-center">Cantidad</th>
              <th className="text-center">Estado</th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-[#FAFAFA]">
            {productos.length === 0 ? (
              <tr className="hover:bg-slate-300">
                <td className="text-center" colSpan={6}>
                  No hay productos disponibles
                </td>
              </tr>
            ) : (
              filteredProductos.map((producto) => (
                <tr
                  className="hover:bg-slate-100 table-body "
                  key={producto.farmacia_producto_id}
                >
                  <td>{producto.articulo}</td>
                  <td>{producto.nombre}</td>
                  <td>{producto.cantidad.toLocaleString()}</td>
                  <td>
                    <div className="m-auto w-fit">
                      <Status
                        status={producto.estado === 1 ? "Inactivo" : "Activo"}
                      />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FarmaciaProductosView;
