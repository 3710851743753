import axios from "axios";
import { useEffect, useState } from "react";
import Status from "../../../../../icons/status";

interface producto {
  producto_id: number;
  nombre: string;
  articulo: string;
  precio: number;
  precio_199: number;
  precio_boost: number;
  precio_reto: number;
  precio_activo: string;
  estado: string;
}

const ProductosList = () => {
  const [productos, setProductos] = useState<producto[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [estadoFilter, setEstadoFilter] = useState("ambos");

  const filteredProductos = productos.filter((producto) => {
    if (
      (estadoFilter === "inactivo" && producto.estado === "Inactivo") ||
      (estadoFilter === "activo" && producto.estado === "Activo") ||
      estadoFilter === "ambos"
    ) {
      return producto.nombre.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });

  const getProductos = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/producto_farmacia/farmacia/${sessionStorage.getItem("user_id")}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setProductos(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductos();
  }, []);

  return (
    <div className="flex-1 flex flex-col gap-4 overflow-clip">
      <h1 className="text-xl">
        <strong>Productos</strong>
      </h1>
      <input
        type="text"
        placeholder="Buscar"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="main-input p-4"
      />
      <select
        value={estadoFilter}
        onChange={(e) => setEstadoFilter(e.target.value)}
        className="main-dropdown "
      >
        <option value="ambos">Todos</option>
        <option value="activo">Activos</option>
        <option value="inactivo">Inactivos</option>
      </select>

      <div
        className="overflow-auto bg-white pb-8 md:pb-0"
        style={{ maxHeight: "calc(70vh)" }}
      >
        <table className="w-full">
          <thead className="bg-gray-50 text-left">
            <tr className="table-head sticky top-0 bg-gray-50">
              <th>Articulo</th>
              <th>Nombre</th>
              <th className="text-center">Precio Reto</th>
              <th className="text-center">Precio Boost</th>
              <th className="text-center">Precio 199</th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {productos.length === 0 ? (
              <tr>
                <td className="text-center" colSpan={6}>
                  No hay productos disponibles
                </td>
              </tr>
            ) : (
              filteredProductos.map((producto) => (
                <tr
                  className="hover:bg-slate-100 table-body"
                  key={producto.producto_id}
                >
                  <td>{producto.articulo}</td>
                  <td className="">{producto.nombre}</td>
                  <td
                    className={`text-center ${
                      producto.precio_activo === "reto" ? "bg-blue-400" : ""
                    }`}
                  >
                    {producto.precio_reto}
                  </td>
                  <td
                    className={`text-center ${
                      producto.precio_activo === "boost" ? "bg-blue-400" : ""
                    }`}
                  >
                    {producto.precio_boost}
                  </td>
                  <td
                    className={`text-center ${
                      producto.precio_activo === "199" ? "bg-blue-400" : ""
                    }`}
                  >
                    {producto.precio_199}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductosList;
