import axios from "axios";
import { useEffect, useState } from "react";
import UserCard from "./UserCard";
// import List from "../retos/List";
import VentaComprobanteModal from "./VentaComprobanteModal";
import TermsAndConditions from "./components/termsAndConditions";
import Video from "../../misc/Video";
import AWS from "aws-sdk";
import GifDisplay from "../../misc/GIFDisplay";

interface user {
  usuario_id: number;
  premios_recibidos: number;
  retos_completado: number;
  puntos: number;
  name: string;
  terminos_aceptados: boolean;
}

const Home = () => {
  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });
  const [modalVenta, setModalVenta] = useState(false);
  const [foto, setFoto] = useState("");
  const [usuario, setUsuario] = useState<user>();
  const [gifUrl, setGifUrl] = useState("");
  const getUser = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/usuarios/home/${sessionStorage?.getItem("email")}`,
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
          params: {
            email: `${sessionStorage?.getItem("email")}`,
          },
        }
      );

      setUsuario(response.data);
      setFoto(response.data.foto);
    } catch (error) {
      console.log(error);
    }
  };

  const getVideoUrl = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/videoOne`,
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
        }
      );
      if (response.data.length > 0) {
        const image = response.data[0];
        const paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: image.link,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            setGifUrl(url);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
    getVideoUrl();
  }, []);

  return (
    <div className="w-full h-screen">
      <UserCard
        foto={foto}
        puntos={usuario?.puntos || 0}
        retos={usuario?.retos_completado || 0}
        name={usuario?.name || ""}
      ></UserCard>
      <div className="w-full m-auto flex flex-col h-full">
        <button
          className="cefa-button my-2"
          onClick={() => setModalVenta(true)}
        >
          Agregar Venta
        </button>
        <div className="grow-1" />

        <GifDisplay gifUrl={gifUrl} gifId={"gifOne"} />
      </div>
      <VentaComprobanteModal view={modalVenta} setView={setModalVenta} />
      {usuario !== undefined && !usuario.terminos_aceptados && (
        <TermsAndConditions
          termsAccepted={usuario.terminos_aceptados}
          usuario_id={usuario.usuario_id}
        />
      )}
    </div>
  );
};

export default Home;
