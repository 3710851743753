import axios from "axios";
import { useEffect, useState } from "react";
import Status from "../../../../../icons/status";
import Swal from "sweetalert2";
import Trash from "../../../../../icons/trash";
import ViewIcon from "../../../../../icons/view";
import ProductoView from "./View";

interface producto {
  producto_id: number;
  nombre: string;
  articulo: string;
  precio: number;
  estado: string;
  precio_boost: number;
  precio_199: number;
  precio_reto: number;
  cantidad_por_caja: number;
}
interface Props {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const ProductosList = ({ counter, setCounter }: Props) => {
  const [productos, setProductos] = useState<producto[]>([]);
  const [deleteCount, setDeleteCount] = useState(0);
  const [productoView, setProductoView] = useState(false);
  const [productoViewID, setProductoViewID] = useState<number>(0);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const generatePageNumbers = () => {
    return Array.from(
      { length: 5 },
      (_, index) => currentPage - 2 + index
    ).filter((page) => page >= 1 && page <= totalPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    getProductos(page);
  };

  const handleSort = (field: any) => {
    const order = field === sortField && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
    getProductos(1);
  };

  const getProductos = async (page = 1) => {
    try {
      const query = `page=${page}&search=${encodeURIComponent(
        searchText
      )}&state=${encodeURIComponent(
        statusFilter
      )}&sortField=${sortField}&sortOrder=${sortOrder}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/productos?${query}`,
        {
          headers: { "auth-token": sessionStorage.getItem("token") },
        }
      );
      setCurrentPage(response.data.pageInfo.currentPage);
      setTotalPages(response.data.pageInfo.totalPages);
      setProductos(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteProducto = async (producto_id: any) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/productos/${producto_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setDeleteCount((prevCount) => prevCount + 1);
      Swal.fire({
        icon: "success",
        title: "Eliminado",
        text: "Se ha eliminado el premio",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al eliminar el premio.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getProductos(currentPage);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [searchText, statusFilter, deleteCount, counter]);

  useEffect(() => {
    getProductos(currentPage);
  }, []);

  return (
    <div className="flex-1 m-auto w-full px-4 flex flex-col gap-4 overflow-clip">
      <input
        type="text"
        placeholder="Buscar"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        className="main-input p-4"
      />
      <select
        value={statusFilter}
        onChange={(e) => setStatusFilter(e.target.value)}
        className="main-dropdown "
      >
        <option value="">Todos</option>
        <option value="Activo">Activos</option>
        <option value="Inactivo">Inactivos</option>
      </select>

      <div
        className="overflow-auto bg-white pb-8 md:pb-0"
        style={{ maxHeight: "calc(60vh)" }}
      >
        <table className="w-full">
          <thead className="bg-gray-50 text-left">
            <tr className="table-head sticky top-0 bg-gray-50">
              <th />
              <th onClick={() => handleSort("articulo")}>
                Articulo
                {sortField === "articulo" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th onClick={() => handleSort("nombre")}>
                Nombre
                {sortField === "nombre" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th className="text-center" onClick={() => handleSort("precio")}>
                Precio
                {sortField === "precio" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th className="text-center">Precio Boost</th>
              <th className="text-center">Precio 199</th>
              <th className="text-center">Precio Reto</th>
              <th className="text-center">Cantidad Por Caja</th>
              <th className="text-center">Acciones</th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {productos.length === 0 ? (
              <tr>
                <td className="text-center" colSpan={6}>
                  No hay productos disponibles
                </td>
              </tr>
            ) : (
              productos.map((producto) => (
                <tr
                  className="hover:bg-slate-100 table-body"
                  key={producto.producto_id}
                >
                  <td>
                    <div className="m-auto w-fit">
                      <Status status={producto.estado} />
                    </div>
                  </td>
                  <td>{producto.articulo}</td>
                  <td className="two-lines">{producto.nombre}</td>
                  <td className="text-center">
                    {producto?.precio?.toLocaleString()}
                  </td>
                  <td className="text-center">
                    {producto?.precio_boost?.toLocaleString()}
                  </td>
                  <td className="text-center">
                    {producto?.precio_199?.toLocaleString()}
                  </td>
                  <td className="text-center">
                    {producto?.precio_reto?.toLocaleString()}
                  </td>
                  <td className="text-center">{producto.cantidad_por_caja}</td>
                  <td>
                    <div className="flex gap-2 m-auto w-fit">
                      <button
                        onClick={() => {
                          setProductoView(!productoView);
                          setProductoViewID(producto.producto_id);
                        }}
                      >
                        <ViewIcon />
                      </button>
                      <button
                        onClick={() => {
                          Swal.fire({
                            title: "¿Estás seguro?",
                            text: "No podrás revertir esta acción",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Sí, eliminar",
                            cancelButtonText: "Cancelar",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteProducto(producto.producto_id);
                            }
                          });
                        }}
                      >
                        <Trash size={15} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div>
        {productos.length > 0 && (
          <div className="flex justify-center items-center gap-4 mt-4">
            <button
              className={`${
                currentPage === 1 ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Anterior
            </button>

            <div className="flex gap-2">
              {generatePageNumbers().map((page) => (
                <button
                  key={page}
                  className={`cefa-button ${
                    currentPage === page ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>

            <button
              className={`${
                currentPage === totalPages ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Siguiente
            </button>
          </div>
        )}
      </div>
      <ProductoView
        productoView={productoView}
        setProductoView={setProductoView}
        productoViewID={productoViewID}
        counter={deleteCount}
        setCounter={setDeleteCount}
      />
    </div>
  );
};

export default ProductosList;
