const close = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0833 40.3333C31.2086 40.3333 39.4167 32.1252 39.4167 22C39.4167 11.8747 31.2086 3.66663 21.0833 3.66663C10.9581 3.66663 2.75 11.8747 2.75 22C2.75 32.1252 10.9581 40.3333 21.0833 40.3333Z"
        fill="#787676"
      />
      <path
        d="M15.5833 16.5L26.5833 27.5M26.5833 16.5L15.5833 27.5"
        stroke="white"
      />
    </svg>
  );
};

export default close;
