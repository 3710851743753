import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AWS from "aws-sdk";
import Visible from "../../icons/visible";
import NotVisible from "../../icons/notVisible";

const Landing = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });

  const [imageOne, setImageOne] = useState("");
  const [imageTwo, setImageTwo] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleGetImages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/landing`
      );

      let tempImageOne = response.data.filter(
        (image: any) => image.placement === "LandingDesktop"
      );
      let tempImageTwo = response.data.filter(
        (image: any) => image.placement === "LandingMobile"
      );

      let paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${tempImageOne[0].link}`,
        Expires: 60 * 60,
      };
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setImageOne(url);
        }
      });

      paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${tempImageTwo[0].link}`,
        Expires: 60 * 60,
      };
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setImageTwo(url);
        }
      });

      setImageTwo(tempImageTwo[0].link);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setLoginInfo({ ...loginInfo, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    try {
      event?.preventDefault();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        loginInfo
      );

      sessionStorage.setItem("token", `${response.data.token}`);
      sessionStorage.setItem("rol", `${response.data.user.role}`);
      sessionStorage.setItem("user_id", `${response.data.user.usuario_id}`);
      sessionStorage.setItem("email", `${response.data.user.email}`);

      if (response.data.user.role === "Usuario") {
        navigate("/user");
      } else {
        navigate("/admin");
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Nos has podido ingresar, por favor verifica tus credenciales",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    handleGetImages();
  }, []);

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="w-1/2 h-full hidden lg:block">
        <img src={imageOne} alt="" className="h-full object-cover" />
      </div>
      <div className="w-full lg:w-1/2 bg-white flex items-center justify-center px-6 md:px-0">
        <div className="w-full max-w-md">
          <div className="">
            <img
              src={require("../../icons/Logo-CefaPlus.png")}
              alt=""
              className="w-40 my-4"
            />
          </div>
          <div className="">
            <img
              src={imageTwo}
              alt=""
              className="w-full h-40 object-cover rounded-md"
            />
          </div>
          <div className="my-10">
            <h1 className="text-3xl font-bold">Inicie Sesión</h1>
            <p>Bienvenido, ingrese sus datos para abrir su cuenta</p>
          </div>

          <form className="w-full" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4 mb-5">
              <div className="block">
                <label htmlFor="" className="">
                  Correo Electrónico
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  className="main-input"
                  required
                  placeholder="Correo Electrónico"
                />
              </div>
              <div className="block relative">
                <label htmlFor="password" className="">
                  Contraseña
                </label>
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  onChange={handleInputChange}
                  className="main-input pr-10"
                  required
                  placeholder="********"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-2 top-12 transform -translate-y-1/2 focus:outline-none"
                >
                  {passwordVisible ? <NotVisible /> : <Visible />}
                </button>
              </div>
              <a href="/password-reset" className="text-[#6CB7A2]  font-bold">
                Olvidé mi contraseña
              </a>
              <button className={`cefa-button`} type="submit">
                Ingresar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Landing;
