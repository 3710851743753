import AWS from "aws-sdk";
import axios from "axios";
import { useState, useEffect } from "react";
import View from "./View";
import Cart from "./Cart";
import Cefamonedas from "../../../../../icons/cefamoneda";
import CartIcon from "../../../../../icons/cart";

interface Premio {
  premio_id: number;
  nombre: string;
  puntos?: number;
  imagen?: string;
  descripcion?: string;
  estado: string;
  signedUrl: string;
}

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

const Premios = () => {
  const [premios, setPremios] = useState<Premio[]>([]);
  const [tempPremios, setTempPremios] = useState<Premio[]>([]);
  const [puntos, setPuntos] = useState<number>(0);
  const [premioView, setPremioView] = useState(false);
  const [premioViewID, setPremioViewID] = useState<number>(0);
  const [cartView, setCartView] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterItem, setFilterItem] = useState("Todos");
  const [counter, setCounter] = useState(0);

  let filteredPremios = tempPremios
    .sort((a, b) => {
      if (filterItem === "descend") {
        if (a.puntos && b.puntos) {
          return a.puntos - b.puntos;
        } else {
          return 0;
        }
      } else if (filterItem === "ascend") {
        if (a.puntos && b.puntos) {
          return b.puntos - a.puntos;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    })
    .filter((premio) =>
      premio.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const sortPremios = (event: any) => {
    setFilterItem(event.target.value);
    switch (event.target.value) {
      case "descend": {
        const sortedPremios = premios.sort((a, b) => {
          if (a.puntos && b.puntos) {
            return a.puntos - b.puntos;
          } else {
            return 0;
          }
        });
        setTempPremios(sortedPremios);
        break;
      }
      case "ascend": {
        const sortedPremios = premios.sort((a, b) => {
          if (a.puntos && b.puntos) {
            return b.puntos - a.puntos;
          } else {
            return 0;
          }
        });
        setTempPremios(sortedPremios);
        break;
      }
      case "belowMyPoints": {
        const filteredPremios = premios.filter((item) => {
          if (item.puntos) {
            return item.puntos < puntos;
          } else {
            return false;
          }
        });
        setTempPremios(filteredPremios);
        break;
      }
      case "all": {
        setTempPremios(premios);
      }
    }
  };

  const getPremios = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/premios/activos`,
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
        }
      );

      const premiosWithSignedUrls = await Promise.all(
        response.data.map(async (premio: any) => {
          const signedUrl = s3.getSignedUrl("getObject", {
            Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
            Key: `${premio.imagen}`,
            Expires: 60 * 60,
          });

          return {
            ...premio,
            signedUrl: signedUrl,
          };
        })
      );

      setPremios(premiosWithSignedUrls);
      setTempPremios(premiosWithSignedUrls);
    } catch (error) {}
  };

  const getPuntos = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/usuarios/puntos/${sessionStorage?.getItem("user_id")}`,
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
        }
      );
      setPuntos(response.data.puntos);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPuntos();
    getPremios();
  }, [counter]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-col gap-4 overflow-clip max-h-screen">
        <div className="flex items-center">
          <p>{puntos}</p>
          <Cefamonedas />
          <div className="grow"></div>
          <div
            className="place-self-end cursor-pointer"
            onClick={() => setCartView(!cartView)}
          >
            <CartIcon />
          </div>
        </div>

        <div className="flex md:flex-col gap-2">
          <input
            type="text"
            placeholder="Buscar"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="main-input p-4"
          />
          <div>
            <select
              id="filterOption"
              onChange={sortPremios}
              className={`main-dropdown`}
            >
              <option value="all">Todos</option>
              <option value="descend">Descendente</option>
              <option value="ascend">Ascendente</option>
              <option value="belowMyPoints">Para Mi</option>
            </select>
          </div>
        </div>
        <ul className="grid-container overflow-auto">
          {filteredPremios.map((premio) => (
            <li
              key={premio.premio_id}
              className="border-2 p-4 rounded"
              onClick={() => {
                setPremioView(!premioView);
                setPremioViewID(premio.premio_id);
              }}
            >
              <div className="flex">
                <p className="text-right">
                  {premio.puntos?.toLocaleString() ?? "N/A"}
                </p>
                <Cefamonedas />
              </div>
              <img
                src={premio.signedUrl}
                alt={premio.nombre}
                className="h-60 m-auto p-3"
              />
              <p>{premio.nombre}</p>
              <p>{premio.descripcion}</p>
            </li>
          ))}
        </ul>
        <div></div>
      </div>
      <View
        premioView={premioView}
        setPremioView={setPremioView}
        premioViewID={premioViewID}
      />
      <Cart
        counter={counter}
        setCounter={setCounter}
        cartView={cartView}
        setCartView={setCartView}
        availablePoints={puntos}
      />
    </div>
  );
};

export default Premios;
