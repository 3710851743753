import React, { useState} from "react";
import axios from "axios";

interface TermsAndConditionsProps {
  termsAccepted: boolean;
  usuario_id: number;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  termsAccepted,
  usuario_id,
}) => {
  const [open, setOpen] = useState(!termsAccepted);

  const handleAccept = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/usuarios/accept-terms/${usuario_id}`,
        {},
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
        }
      );

      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (termsAccepted || !open) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-2xl max-h-[90vh] flex flex-col">
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Terminos y Condiciones</h2>
          <div className="border border-gray-300 rounded p-4 mb-4 h-96 overflow-y-auto">
            <div className="container mx-auto p-6">
              <h1 className="text-2xl font-bold mb-4">
                Términos y Condiciones
              </h1>
              <p className="mb-4">
                Al participar acepto los siguientes términos y condiciones. El
                presente documento contiene los términos y condiciones de
                participación en la dinámica y plataforma digital de uso
                exclusivo de Clientes de CEFA “RANKING CEFA”.
              </p>
              <h2 className="text-xl font-semibold mb-2">
                Requisitos para participar
              </h2>
              <ul className="list-disc ml-6 mb-4">
                <li>
                  Mayor de edad con Cédula -documento vigente ante el gobierno
                  de Costa Rica.
                </li>
                <li>Pertenecer a la red de Clientes -Farmacias de CEFA.</li>
                <li>Ser integrante activo de una farmacia red de CEFA.</li>
                <li>
                  Proporcionar datos de inscripción tales como: Nombre, Correo,
                  Número Celular para obtener invitación a la plataforma digital
                  “RANKING CEFA”.
                </li>
                <li>
                  Generar y tener un perfil activo dentro de la plataforma
                  “RANKING CEFA”.
                </li>
                <li>
                  Contar con dispositivo electrónico (celular, computadora, etc)
                  que disponga de cámara e internet cumpliendo con los
                  requisitos indispensables para ingresar evidencias de venta de
                  productos participantes en la plataforma participando en la
                  dinámica para generar CEFAMONEDAS.
                </li>
              </ul>

              <h2 className="text-xl font-semibold mb-2">
                Requisitos para generar CEFAMONEDAS
              </h2>
              <ul className="list-disc ml-6 mb-4">
                <li>
                  Registrar información veraz y precisa requerida en la
                  plataforma por medio de ticket/recibo de venta de productos
                  participantes.
                </li>
                <li>
                  Sólo se recibirán solicitudes de tickets/recibos de venta de
                  productos participantes por medio de la plataforma
                  correctamente subidos, cualquier otra dinámica queda excluida.
                </li>
                <li>
                  No se aceptarán participaciones que (a) sean generadas
                  automáticamente por ordenador o cualquier otro sistema; (b)
                  sean completadas de forma masiva; o (c) solicitudes ilegibles,
                  incompletas, fraudulentas, duplicadas, alteradas,
                  reconstruidas, falsificadas o manipuladas, se dará fallo de
                  información rechazando la solicitud.
                </li>
              </ul>

              <h2 className="text-xl font-semibold mb-2">
                Requisitos para redimir/cambiar premios
              </h2>
              <ul className="list-disc ml-6 mb-4">
                <li>
                  Todo asociado que reclame o reciba el premio o beneficio por
                  parte de la plataforma “RANKING CEFA” conlleva la obligación
                  por parte del participante del conocimiento y aceptación
                  expresa de las disposiciones contempladas en este lineamiento
                  y sus modificaciones.
                </li>
                <li>
                  El asociado debe tener un documento de identificación válido y
                  vigente reconocido por la República de Costa Rica.
                </li>
                <li>
                  Tener los puntos equivalentes a los premios en plataforma.
                </li>
                <li>
                  El haber subido verazmente y correctamente la información para
                  acceder a “CEFAMONEDAS”.
                </li>
                <li>
                  Pedir los premios correctamente por medio de la plataforma
                  “RANKING CEFA”.
                </li>
                <li>
                  Los valores de CEFAMONEDAS y premios pueden cambiar sin previo
                  aviso.
                </li>
                <li>
                  Una vez realizado el pedido por medio de la plataforma, se
                  procederá a contactar a los favorecidos por medio de correo
                  electrónico o teléfono.
                </li>
                <li>
                  Es necesario que se firme un documento de aceptación de premio
                  y entrega con copia de su cédula o documento vigente.
                </li>
              </ul>

              <h2 className="text-xl font-semibold mb-2">Generales</h2>
              <ul className="list-disc ml-6 mb-4">
                <li>
                  Todo asociado conoce y acepta los términos y condiciones,
                  cambios y adaptaciones que se establecen en estos lineamientos
                  sin previo aviso.
                </li>
                <li>
                  Al participar, autoriza que su nombre e imagen aparezca en
                  publicaciones y demás medios publicitarios relacionados con la
                  dinámica.
                </li>
                <li>
                  Las controversias serán resueltas mediante el mecanismo de
                  conciliación y arbitraje conforme al Reglamento de la Cámara
                  de Comercio de Costa Rica.
                </li>
              </ul>
            </div>
          </div>
          <button
            type="button"
            onClick={handleAccept}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            Aceptar terminos y condiciones
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
