import { useRef, useEffect, forwardRef } from "react";

interface VideoPlayerProps {
  videoUrl: any;
  videoId: string;
}

export interface VideoPlayerRef {
  play: () => Promise<void>;
  pause: () => void;
}

const VideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>(
  ({ videoUrl, videoId }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      const playVideo = async () => {
        if (videoRef.current) {
          try {
            await videoRef.current.play();
          } catch (error) {
            console.log(`Autoplay was prevented for video ${videoId}:`, error);
          }
        }
      };

      playVideo();
    }, [videoId]);

    return (
      <div className="video-player max-w-2xl m-auto">
        <video
          ref={videoRef}
          src={videoUrl}
          width="100%"
          className="rounded-2xl"
          loop
          playsInline
          muted
          id={`video-${videoId}`}
        >
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
);

export default VideoPlayer;
