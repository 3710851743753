import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import ImageUploader from "../../misc/ImageUploader";
import AWS from "aws-sdk";
import Trash from "../../../../../icons/trash";
import Save from "../../../../../icons/save";
import Close from "../../../../../icons/close";
import Swal from "sweetalert2";

interface Props {
  view: boolean;
  setView: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Producto {
  producto_id: number;
  nombre: string;
  articulo: string;
  cantidad: number;
  unidad: string;
  cantidad_por_caja: number;
}

interface VentaComprobante {
  numero_factura: number;
}

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

const VentaComprobanteModal = ({ view, setView }: Props) => {
  const [venta, setVenta] = useState<VentaComprobante>({
    numero_factura: 0,
  });
  const [tempProducto, setTempProducto] = useState<Producto>({
    unidad: "Caja",
  } as Producto);
  const [productList, setProductList] = useState<Producto[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Producto[]>([]);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();

  const handleCSVUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const products = results.data;

          updateSelectedProductsFromCSV(products);
        },
      });
    }
  };

  const updateSelectedProductsFromCSV = (products: any[]) => {
    const productUpdates = products.map((product: any) => ({
      articulo: product.Articulo,
      unidad: product.Unidad,
      cantidad: Number(product.Cantidad),
    }));

    const updatedProducts = [...selectedProducts];

    productUpdates.forEach((update) => {
      const productIndex = updatedProducts.findIndex(
        (p) => p.articulo === update.articulo
      );

      if (productIndex > -1) {
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          cantidad: updatedProducts[productIndex].cantidad + update.cantidad,
        };
      } else {
        const fullProductDetails = productList.find(
          (p) => p.articulo === update.articulo
        );

        if (fullProductDetails) {
          updatedProducts.push({
            ...fullProductDetails,
            cantidad: update.cantidad,
            unidad: update.unidad,
          });
        } else {
          console.error("Product details not found for ID:", update.articulo);
        }
      }
    });

    setSelectedProducts(updatedProducts);
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setTempProducto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleVentaChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setVenta({ ...venta, [name]: value });
  };

  const donwloadTemplate = async () => {
    const paramsS3 = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
      Key: `private/Template.xlsx`,
      Expires: 60 * 60,
    };
    s3.getSignedUrl("getObject", paramsS3, (err, url) => {
      if (err) {
        console.error(err);
      } else {
        window.open(url, "_blank");
      }
    });
  };

  const getProductos = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/producto_farmacia/farmacia/${sessionStorage.getItem("user_id")}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setProductList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const removeProductById = (productoId: number, unidad: string) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.filter(
        (product) =>
          !(product.producto_id === productoId && product.unidad === unidad)
      )
    );
  };

  const newProduct = () => {
    if (
      tempProducto.producto_id === undefined ||
      tempProducto.producto_id === 0 ||
      tempProducto.unidad === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Por favor, seleccione un producto.",
      });
      return;
    }
    const selectedProduct = productList.find(
      (product) => product.producto_id === tempProducto.producto_id
    );

    if (selectedProduct) {
      setSelectedProducts((prevSelectedProducts: any) => {
        const existingProductIndex = prevSelectedProducts.findIndex(
          (p: any) =>
            p.producto_id === tempProducto.producto_id &&
            p.unidad === tempProducto.unidad
        );
        if (existingProductIndex >= 0) {
          const newQuantityType = tempProducto.unidad;
          const newQuantity =
            Number(prevSelectedProducts[existingProductIndex].cantidad) +
            Number(tempProducto.cantidad);

          if (newQuantity <= 0) {
            return prevSelectedProducts.filter(
              (p: any) => p.producto_id !== tempProducto.producto_id
            );
          }
          const updatedSelectedProducts = [...prevSelectedProducts];
          updatedSelectedProducts[existingProductIndex] = {
            ...updatedSelectedProducts[existingProductIndex],
            cantidad: newQuantity,
            articulo: updatedSelectedProducts[existingProductIndex].articulo,
            unidad: newQuantityType,
          };

          return updatedSelectedProducts;
        } else {
          if (!selectedProduct.articulo) {
            console.error("Product data is incomplete!");
            return prevSelectedProducts;
          }

          return [
            ...prevSelectedProducts,
            {
              producto_id: selectedProduct.producto_id,
              nombre: selectedProduct.nombre,
              cantidad: Number(tempProducto.cantidad),
              articulo: selectedProduct.articulo,
              unidad: tempProducto.unidad,
            },
          ];
        }
      });
    } else {
      console.error("Product not found!");
    }
  };

  function validateNumberLength(event: any) {
    const value = event.target.value;

    // Limit input to 12 characters
    if (value.length > 12) {
      event.target.value = value.slice(0, 12); // Truncate to 12 digits if more are entered
    }

    // Provide custom validation message
    if (value.length !== 12) {
      event.target.setCustomValidity(
        "The number must be exactly 12 digits long"
      );
    } else {
      event.target.setCustomValidity(""); // Clear the custom validity if the length is correct
    }
  }

  const handleSubmit = async () => {
    try {
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (
        selectedProducts.length === 0 ||
        !selectedFile ||
        !venta.numero_factura
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Por favor, complete todos los campos.",
          timer: 1700,
        });
        return;
      } else if (venta.numero_factura.toString().length !== 12) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "El número de factura debe tener 12 dígitos.",
          timer: 1700,
        });
        return;
      }

      const currentDate = new Date();

      if (selectedFile) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `ventacomprobantes/${currentDate}-${selectedFile?.name}`,
          Body: selectedFile,
        };
        await s3.upload(uploadParams).promise();
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/venta_comprobante`,
        {
          venta: venta,
          productos: selectedProducts,
          file: `ventacomprobantes/${currentDate}-${selectedFile?.name}`,
          usuario_id: sessionStorage.getItem("user_id"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: "La venta se ha registrado correctamente.",
      });
      setVenta({ numero_factura: 0 });
      setSelectedProducts([]);
      setTempProducto({} as Producto);
      setSelectedFile(undefined);
      setView(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log("Error data:", error.response.data);
          console.log("Error status:", error.response.status);

          if (error.response.status === 404) {
            const unfoundProducts = error.response.data.unfoundProducts;

            showUnfoundProductsAlert(unfoundProducts);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text:
                error.response.data.message ||
                "Ha ocurrido un error al enviar los datos.",
            });
          }
        } else if (error.request) {
          console.log("No response:", error.request);
          Swal.fire({
            icon: "error",
            title: "Error de Red",
            text: "No se pudo recibir una respuesta del servidor.",
          });
        } else {
          console.log("Error", error.message);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error en la solicitud: " + error.message,
          });
        }
      } else {
        console.error("Error:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Un error inesperado ocurrió.",
        });
      }
    }
  };

  const showUnfoundProductsAlert = (unfoundProducts: any) => {
    const htmlContent = unfoundProducts
      .map((product: any) => `<tr><td>${product.nombre}</td></tr>`)
      .join("");

    Swal.fire({
      title: "Productos no encontrados",
      icon: "warning",
      html: `
        <p>Los siguientes productos no están disponibles:</p>
        <table class="table">
          <thead>
            <tr><th>Producto</th></tr>
          </thead>
          <tbody>
            ${htmlContent}
          </tbody>
        </table>
      `,
      confirmButtonText: "Aceptar",
    });
  };

  const handleProductoChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selected_producto = event.target.value;
    const foundProduct = productList.find(
      (producto) => producto.nombre === selected_producto
    );

    if (!foundProduct) {
      return;
    }

    if (foundProduct.cantidad_por_caja === 0) {
      setTempProducto((prevState) => ({
        ...prevState,
        unidad: "Caja",
        producto_id: foundProduct.producto_id,
        nombre: foundProduct.nombre,
        cantidad_por_caja: foundProduct.cantidad_por_caja,
      }));
    } else {
      setTempProducto((prevState) => ({
        ...prevState,
        nombre: foundProduct.nombre,
        producto_id: foundProduct.producto_id,
        cantidad_por_caja: foundProduct.cantidad_por_caja,
        unidad: "Unidad",
      }));
    }
  };

  // If using a form, ensure to reset the select when the product changes
  useEffect(() => {
    const unidadSelect = document.getElementById("unidad") as HTMLSelectElement;
    if (unidadSelect) {
      unidadSelect.value =
        tempProducto && tempProducto.cantidad_por_caja !== 0
          ? "Unidad"
          : "Caja";
    }
  }, [tempProducto]);

  useEffect(() => {
    if (view) {
      setTimeout(() => {
        setIsClosing(false);
      }, 1000);
    }
    getProductos();
  }, [view]);

  const openFileSelector = () => {
    const fileInput = document.getElementById("csvInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("The file input element was not found.");
    }
  };

  return (
    <>
      {view || isClosing ? (
        <div className={`${view ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`bg-white absolute md:relative h-full max-h-screen overflow-auto min-w-full md:min-w-0 w-screen left-0 p-2 ${
              isClosing ? "animate-slide-out" : "animate-slide-in"
            }`}
          >
            <div className="flex items-center justify-center gap-2">
              <p>
                <strong>Registrar una venta</strong>
              </p>
              <button onClick={handleSubmit}>
                <Save size={36} />
              </button>
              <div className="grow" />
              <button
                onClick={() => {
                  if (view) {
                    setIsClosing(true);
                    setTimeout(() => {
                      setView(false);
                      setIsClosing(false);
                    }, 1000);
                  } else {
                    setView(true);
                  }
                }}
              >
                <Close />
              </button>
            </div>
            <div className="flex flex-col gap-5">
              <div>
                <form
                  action=""
                  className="flex flex-col gap-4"
                  onSubmit={handleSubmit}
                >
                  <label>Foto de la factura</label>
                  <ImageUploader
                    setSelectedFile={setSelectedFile}
                    inputId="UsuarioVentaComprobante"
                  />
                  <div className="block">
                    <label htmlFor="" className="">
                      Numero de factura (últimos 12 dígitos de clave numérica
                      factura)
                    </label>
                    <input
                      type="number"
                      id="numero_factura"
                      name="numero_factura"
                      onChange={handleVentaChange}
                      maxLength={12}
                      onInput={validateNumberLength}
                      className="main-input"
                      required
                    />
                  </div>
                  <hr />
                  <div>
                    <label htmlFor="name">Producto</label>
                    <input
                      type="text"
                      id="producto_id"
                      name="producto_id"
                      onChange={handleProductoChange}
                      list="names"
                      className="main-input"
                      required
                    />
                    <datalist id="names">
                      {productList.map((producto) => (
                        <option
                          key={`${producto.producto_id}`}
                          value={`${producto.nombre}`}
                        />
                      ))}
                    </datalist>
                  </div>
                  <div className="block">
                    <label htmlFor="" className="">
                      Cantidad*
                    </label>
                    <input
                      type="number"
                      id="cantidad"
                      name="cantidad"
                      onChange={handleInputChange}
                      className="main-input"
                      required
                    />
                  </div>

                  <div className="block">
                    <label htmlFor="unidad">Unidad*</label>
                    <select
                      id="unidad"
                      name="unidad"
                      onChange={handleInputChange}
                      className="custom-select main-input"
                      value={tempProducto.unidad}
                      required
                    >
                      <option value="Caja">Caja</option>
                      {tempProducto && tempProducto.cantidad_por_caja !== 0 && (
                        <option value="Unidad">Unidad</option>
                      )}
                    </select>
                  </div>

                  <button
                    className="cefa-button"
                    onClick={newProduct}
                    type="button"
                  >
                    Agregar producto a la venta
                  </button>
                </form>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-4">
              <div
                className="overflow-auto bg-white pb-8 md:pb-0"
                style={{ maxHeight: "calc(60vh)" }}
              >
                <table className="w-full">
                  <thead className="bg-gray-50 top-0 sticky">
                    <tr className="table-head text-left">
                      <th>Articulo</th>
                      <th>Nombre</th>
                      <th className="text-center">Unidad</th>
                      <th className="text-center">Cantidad</th>
                      <th className="text-center">Acciones</th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-[#FAFAFA]">
                    {selectedProducts.length === 0 ? (
                      <tr className="hover:bg-slate-300">
                        <td className="text-center" colSpan={6}>
                          No hay productos seleccionados
                        </td>
                      </tr>
                    ) : (
                      selectedProducts.map((producto) => (
                        <tr
                          className="hover:bg-slate-100 table-body "
                          key={producto.producto_id}
                        >
                          <td>{producto.articulo}</td>
                          <td>{producto.nombre}</td>
                          <td>{producto.unidad}</td>
                          <td className="text-center">{producto.cantidad}</td>
                          <td>
                            <div className="m-auto w-fit">
                              <button
                                onClick={() =>
                                  removeProductById(
                                    producto.producto_id,
                                    producto.unidad
                                  )
                                }
                              >
                                <Trash size={15} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <hr className="py-4" />
            {/* <p>
              Si quieres llenar esta seccion con un CSV sigue las siguientes
              instrucciones:
            </p>
            <p>1. Descarga la plantilla</p>
            <p>
              2. Llena la plantilla con los datos de los productos, para ver la
              información con la que lo tienes que llenar ve a la pagina de
              productos
            </p>
            <p>3. Sube el archivo en formato CSV</p>
            <button
              type="button"
              className="cefa-button my-4"
              onClick={donwloadTemplate}
            >
              Descargar plantilla
            </button>
            <div
              className="border border-gray-300 rounded-lg shadow-md flex flex-col items-center cursor-pointer"
              onClick={openFileSelector}
            >
              <p className="p-4">Click para subir reporte</p>
              <input
                id="csvInput"
                type="file"
                accept=".csv"
                className="hidden"
                onChange={(event) => {
                  handleCSVUpload(event);
                }}
              />
            </div> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default VentaComprobanteModal;
