import List from "./List";
import { useEffect, useState } from "react";
import New from "./New";
import Add from "../../../../../icons/add";
import checkRoles from "../../misc/checkRoles";

const Premios = () => {
  const [view, setView] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {}, [counter]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center py-4">
        <p className="font-bold grow text-2xl">
          <strong>Premios</strong>
        </p>
        {checkRoles(["Admin"]) && (
          <div>
            <button onClick={() => setView(!view)} className="cefa-button">
              <p>Agregar Premio</p>
              <Add />
            </button>
          </div>
        )}
      </div>
      <New
        view={view}
        setView={setView}
        counter={counter}
        setCounter={setCounter}
      />
      <div className="flex-grow overflow-hidden">
        <List counter={counter} setCounter={setCounter} />
      </div>
    </div>
  );
};

export default Premios;
