import React, { useState } from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import axios from "axios";
import { formatToMMDDYYYY } from "../../../../../utils/dates";
import farmacia from "../../../../../icons/menu/farmacia";
import checkRoles from "../../misc/checkRoles";

interface Fecha {
  initialDate: Date;
  finalDate: Date;
}

const ExcelExport = () => {
  const [fecha, setFecha] = useState<Fecha>({} as Fecha);

  //Function to handle the input change
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFecha({ ...fecha, [name]: value });
  };

  const exportToCSV = async (fileName: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report/usuarios`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const responseData = response.data;

      const dataToExport = responseData.map((item: any) => ({
        usuario_id: item.usuario_id,
        nombre: item.nombre,
        apellido: item.apellido,
        email: item.email,
        puntos: item.puntos,
        farmacia_id: item.farmacia_id,
        farmacia: item.farmacia,
      }));

      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    } catch (error) {
      console.error("Error exporting data to Excel:", error);
    }
  };

  const pedidosReport = async (fileName: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report/allpedidos`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const responseData = response.data;
      const dataToExport = responseData.map((item: any) => ({
        pedido_id: item.pedido_id,
        pedido_estado: item.estado,
        fecha_creacion: formatToMMDDYYYY(item.fecha_creacion),
        premio_id: item.premio_id,
        premio_nombre: item.premio_nombre,
        cantidad: item.cantidad,
        usuario_id: item.usuario_id,
        usuario_nombre: item.usuario_nombre,
        usuario_apellido: item.usuario_apellido,
        usuario_email: item.usuario_email,
      }));

      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    } catch (error) {
      console.error("Error exporting data to Excel:", error);
    }
  };

  const handleSubmitComprobantes = async (event: any) => {
    try {
      event.preventDefault();
      const data = {
        initialDate: fecha.initialDate,
        finalDate: fecha.finalDate,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/report/comprobantes`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const responseData = response.data;
      // Transform response data to have one line per product
      const transformedData = responseData.flatMap((item: any) =>
        item.products.map((product: any) => ({
          id: product.venta_comprobante_producto_id,
          estado: item.estado,
          fecha_creacion: formatToMMDDYYYY(item.fecha_creacion),
          farmacia_id: item.farmacia_id,
          farmacia: item.farmacia,
          usuario_id: item.usuario_id,
          usuario_nombre: item.usuario_nombre,
          usuario_apellido: item.usuario_apellido,
          usuario_email: item.usuario_email,
          numero_factura: item.numero_factura,
          SKU: product.SKU,
          producto_nombre: product.producto_nombre,
          precio_usado: product.precio_activo,
          cantidad: product.cantidad,
          unidad: product.unidad,
          puntos: product.puntos,
        }))
      );

      // Convert transformed data to worksheet
      const ws = XLSX.utils.json_to_sheet(transformedData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      // Save the Excel file
      FileSaver.saveAs(dataBlob, "comprobantes.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-5 p-4">
        <button
          className="cefa-button"
          onClick={() => exportToCSV("Reportes Usuarios")}
        >
          Reporte Usuarios
        </button>
        <button
          className="cefa-button"
          onClick={() => pedidosReport("Reportes Pedidos")}
        >
          Reporte Pedidos
        </button>
        {checkRoles(["Admin"]) && (
          <form action="" onSubmit={handleSubmitComprobantes}>
            <div className="flex gap-4">
              <div className="grow">
                <label htmlFor="" className="">
                  Fecha inicial
                </label>
                <input
                  type="date"
                  name="initialDate"
                  className="main-input"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="grow">
                <label htmlFor="" className="">
                  Fecha final
                </label>
                <input
                  name="finalDate"
                  type="date"
                  className="main-input"
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <button className="cefa-button my-3">Reporte Comprobantes</button>
          </form>
        )}
      </div>
    </>
  );
};

export default ExcelExport;
