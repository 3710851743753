import { useEffect, useRef, useState } from "react";
import axios from "axios";
import FarmaciaUsuarios from "./UsuariosList";
import ProductoInventoryModal from "./Inventory";
import Close from "../../../../../icons/close";
import ProductoList from "./ProductosList";
import ArrowForward from "../../../../../icons/arrowForward";
import Save from "../../../../../icons/save";
import StatusToggle from "../../../../../icons/toggle";
import Swal from "sweetalert2";

interface Farmacia {
  farmacia_id: number;
  nombre: string;
  direccion: string;
  telefono: string;
  email: string;
  estado: string;
  asesor: string;
  id_cefa: string;
}

interface Props {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  farmaciaView: boolean;
  setFarmaciaView: React.Dispatch<React.SetStateAction<boolean>>;
  farmaciaViewID: number;
}

const FarmaciaView = ({
  counter,
  setCounter,
  farmaciaView,
  setFarmaciaView,
  farmaciaViewID,
}: Props) => {
  const [farmacia, setFarmacia] = useState<Farmacia>({
    farmacia_id: 0,
    nombre: "",
    direccion: "",
    telefono: "",
    email: "",
    estado: "",
    asesor: "",
    id_cefa: "",
  });
  const [inventory, setInventory] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [counterInventory, setCounterInventory] = useState(0);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFarmacia({ ...farmacia, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const data = {
        nombre: farmacia.nombre,
        direccion: farmacia.direccion,
        telefono: farmacia.telefono,
        email: farmacia.email,
        asesor: farmacia.asesor,
        id_cefa: farmacia.id_cefa,
      };
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/farmacias/${farmaciaViewID}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCounter((counter) => counter + 1);
      setFarmaciaView(!farmaciaView);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "La farmacia ha sido actualizada con éxito.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al actualizar la farmacia.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const getFarmacia = async (farmaciaViewID: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/farmacias/${farmaciaViewID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setFarmacia(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitButton = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  const setStatus = async () => {
    try {
      let tempState = farmacia.estado === "Activo" ? "Inactivo" : "Activo";
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/farmacias/state/${farmacia.farmacia_id}`,
        { estado: tempState },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setFarmacia({ ...farmacia, estado: tempState });
      setCounter((counter) => counter + 1);
      setFarmaciaView(!farmaciaView);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "El reto ha sido actualizado con éxito.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al actualizar el reto.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  useEffect(() => {
    if (farmaciaView === false) {
      setTimeout(() => {
        setFarmacia({} as Farmacia);
      }, 500);
    } else {
      getFarmacia(farmaciaViewID);
    }
  }, [farmaciaView]);

  return (
    <>
      {farmaciaView ? (
        <div className={`${farmaciaView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white w-screen md:w-1/3 md:min-w-0 min-w-full overflow-auto ${
              farmaciaView ? "animate-slide-in" : "animate-slide-out"
            }`}
          >
            <div className="w-full sticky top-0 bg-white py-6 px-10 z-20">
              <div className="flex items-center w-full ">
                <p className="text-lg">
                  <strong>{farmacia.nombre}</strong>
                </p>
                <div className="grow" />
                <button
                  onClick={() => {
                    setFarmaciaView(!farmaciaView);
                  }}
                >
                  <Close />
                </button>
              </div>
              <div className="grow flex flex-col m-auto">
                <div className="flex gap-2 flex-row items-center justify-center">
                  <button onClick={handleSubmitButton}>
                    <Save size={36} />
                  </button>
                  <div className="grow"></div>
                  <div>
                    <StatusToggle
                      status={farmacia.estado}
                      setStatus={setStatus}
                    />
                  </div>
                  <div>
                    <button
                      className="cefa-button flex gap-2"
                      onClick={() => {
                        setInventory(!inventory);
                      }}
                    >
                      <p> Modificar Inventario</p>
                      <ArrowForward />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="overflow-hidden px-10">
              <form
                ref={formRef}
                action=""
                className="flex flex-col gap-3"
                onSubmit={handleSubmit}
              >
                <div className="flex">
                  <div className="grow"></div>
                </div>
                <div className="block w-full">
                  <label htmlFor="" className="">
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={farmacia.nombre}
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
                <div className="block">
                  <label htmlFor="" className="">
                    Dirección
                  </label>
                  <input
                    type="text"
                    id="direccion"
                    name="direccion"
                    value={farmacia.direccion}
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
                <div className="block">
                  <label htmlFor="" className="">
                    Telefono
                  </label>
                  <input
                    type="number"
                    id="telefono"
                    name="telefono"
                    value={farmacia.telefono}
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
                <div className="block">
                  <label htmlFor="" className="">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={farmacia.email}
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
                <div className="block ">
                  <label htmlFor="" className="">
                    Asesor
                  </label>
                  <input
                    type="text"
                    id="asesor"
                    name="asesor"
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
                <div className="block">
                  <label htmlFor="" className="">
                    ID CEFA Interno
                  </label>
                  <input
                    type="number"
                    id="id_cefa"
                    name="id_cefa"
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
              </form>

              <div className="text-lg text-center my-4">
                <strong>Lista de Usuarios</strong>
              </div>

              <FarmaciaUsuarios farmaciaViewID={farmaciaViewID} />
              <div className="text-lg text-center my-4">
                <strong>Lista de Productos</strong>
              </div>
              <ProductoList
                farmaciaViewID={farmaciaViewID}
                counterInventory={counterInventory}
                setCounterInventory={setCounterInventory}
              />
            </div>
          </div>
          <div className="vertical-line" />
          <div>
            <ProductoInventoryModal
              farmaciaID={farmaciaViewID}
              setFarmaciaInventoryView={setInventory}
              farmaciaInventoryView={inventory}
              counterInventory={counterInventory}
              setCounterInventory={setCounterInventory}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FarmaciaView;
