import React from "react";

interface GifDisplayProps {
  gifUrl: any;
  gifId: string;
}

const GifDisplay: React.FC<GifDisplayProps> = ({ gifUrl, gifId }) => {
  return (
    <div className="gif-display max-w-2xl m-auto">
      <img
        src={gifUrl}
        alt={`GIF ${gifId}`}
        className="rounded-2xl w-full"
        id={`gif-${gifId}`}
      />
    </div>
  );
};

export default GifDisplay;
