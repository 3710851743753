import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Close from "../../../../../icons/close";
import Trash from "../../../../../icons/trash";
import Save from "../../../../../icons/save";
import StatusToggle from "../../../../../icons/toggle";
import Swal from "sweetalert2";

interface Usuario {
  usuario_id: number;
  estado: string;
  nombre: string;
  apellido: string;
  email: string;
  telefono: number;
  puntos: number;
  pais: string;
  provincia: string;
  canton: string;
  distrito: string;
  barrio: string;
  direccion: string;
  farmacia: string;
}

interface Farmacia {
  farmacia_id: number;
  nombre: string;
  direccion: string;
  telefono: string;
  email: string;
}

interface Props {
  usuarioView: boolean;
  setUsuarioView: React.Dispatch<React.SetStateAction<boolean>>;
  usuarioViewID: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const UsuarioView = ({
  usuarioViewID,
  usuarioView,
  setUsuarioView,
  setCounter,
}: Props) => {
  const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
  const [farmacias, setFarmacias] = useState<Farmacia[]>([]);
  const [selectedFarmacia, setSelectedFarmacia] = useState(0);
  const formRef = useRef<HTMLFormElement>(null);
  const [usuarioCounter, setUsuarioCounter] = useState(0);
  const [activeTab, setActiveTab] = useState("basica");

  const getUsuario = async (usuario_id: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${usuario_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setUsuario(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFarmacias = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/farmacias/allFarmacias`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setFarmacias(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFarmaciaChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedName = event.target.value;
    const selectedFarmaciaTemp = farmacias.find(
      (farmacia) => farmacia.nombre === selectedName
    );
    setSelectedFarmacia(selectedFarmaciaTemp?.farmacia_id || 0);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setUsuario({ ...usuario, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const data = {
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        email: usuario.email,
        telefono: usuario.telefono,
        pais: usuario.pais,
        provincia: usuario.provincia,
        canton: usuario.canton,
        distrito: usuario.distrito,
        barrio: usuario.barrio,
        direccion: usuario.direccion,
        farmacia_id: selectedFarmacia,
      };
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${usuarioViewID}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCounter((counter) => counter + 1);
      setUsuarioView(!usuarioView);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "Usuario atualizado con éxito.",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Opps!",
        text: "Ha ocurrido un error",
      });
      console.log(error);
    }
  };

  const deleteUsuario = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${usuario.usuario_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCounter((counter) => counter + 1);
      setUsuarioView(!usuarioView);
      Swal.fire({
        icon: "success",
        title: "Eliminado",
        text: "Se ha eliminado el usuario",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const setStatus = async () => {
    try {
      let tempState = usuario.estado === "Activo" ? "Inactivo" : "Activo";
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/usuarios/state/${usuario.usuario_id}`,
        { estado: tempState },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setUsuario({ ...usuario, estado: tempState });
      setCounter((counter) => counter + 1);
      setUsuarioView(!usuarioView);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "El usuario ha sido activado con éxito.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al activar el usuario.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const handleSubmitButton = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  const resendInvitation = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/usuarios/resend-invitation`,
        { email: usuario.email },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Invitación reenviada",
        text: "Se ha reenviado la invitación al usuario.",
      });
    } catch (error) {
      console.error("Error resending invitation:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo reenviar la invitación. Por favor, intente de nuevo.",
      });
    }
  };

  useEffect(() => {
    if (usuarioView === false) {
      setTimeout(() => {
        setUsuario({} as Usuario);
        setUsuarioCounter(0);
      }, 500);
    } else {
      getUsuario(usuarioViewID);
      getFarmacias();
    }
  }, [usuarioView]);

  return (
    <>
      {usuarioView ? (
        <div className={`${usuarioView ? "bg-drawer " : "hidden"}`}>
          <div className="grow"></div>
          <div className="h-full bg-white overflow-auto w-screen md:w-1/3 md:min-w-fit min-w-full">
            <div className="p-4 sticky top-0 bg-white shadow-sm">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold flex items-center">
                  <span className="mr-3">
                    {usuario.nombre} {usuario.apellido}
                  </span>
                  <StatusToggle status={usuario.estado} setStatus={setStatus} />
                </h2>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={resendInvitation}
                    className="p-2 text-green-600 hover:bg-green-100 rounded-full transition-colors"
                    title="Reenviar invitación"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={handleSubmitButton}
                    className="p-2 text-blue-600 hover:bg-blue-100 rounded-full transition-colors"
                    title="Guardar cambios"
                  >
                    <Save size={20} />
                  </button>
                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "¿Estás seguro?",
                        text: "No podrás revertir esta acción",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Sí, eliminar",
                        cancelButtonText: "Cancelar",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteUsuario();
                        }
                      });
                    }}
                    type="button"
                    className="p-2 text-red-600 hover:bg-red-100 rounded-full transition-colors"
                    title="Eliminar usuario"
                  >
                    <Trash size={20} />
                  </button>
                  <button
                    onClick={() => setUsuarioView(!usuarioView)}
                    className="p-2 text-gray-500 hover:bg-gray-100 rounded-full transition-colors"
                    title="Cerrar"
                  >
                    <Close />
                  </button>
                </div>
              </div>

              <div className="mb-4">
                <ul className="flex border-b">
                  <li className="mr-1">
                    <button
                      className={`bg-white inline-block py-2 px-4 ${
                        activeTab === "basica"
                          ? "text-blue-500 border-l border-t border-r rounded-t"
                          : "text-blue-500 hover:text-blue-800"
                      } font-semibold`}
                      type="button"
                      onClick={() => handleTabClick("basica")}
                    >
                      Información Básica
                    </button>
                  </li>
                  <li className="mr-1">
                    <button
                      className={`bg-white inline-block py-2 px-4 ${
                        activeTab === "otra"
                          ? "text-blue-500 border-l border-t border-r rounded-t"
                          : "text-blue-500 hover:text-blue-800"
                      } font-semibold`}
                      type="button"
                      onClick={() => handleTabClick("otra")}
                    >
                      Información Adicional
                    </button>
                  </li>
                </ul>
              </div>

              <div className="grow px-2 mt-2">
                <div>
                  <form ref={formRef} className="" onSubmit={handleSubmit}>
                    <div
                      id="tab-basica"
                      className={`flex flex-col gap-4
                        ${activeTab === "basica" ? "" : "hidden"}`}
                    >
                      <div>
                        <label>Farmacia</label>
                        <input
                          type="text"
                          id="farmacia_id"
                          name="farmacia_id"
                          defaultValue={usuario.farmacia}
                          onChange={handleFarmaciaChange}
                          list="names"
                          className="main-input"
                        />
                        <datalist id="names">
                          {farmacias.map(({ farmacia_id, nombre }) => (
                            <option
                              key={`${farmacia_id}`}
                              value={`${nombre}`}
                            />
                          ))}
                        </datalist>
                      </div>
                      <div className="flex gap-4">
                        <div className="grow">
                          <label>Nombre*</label>
                          <input
                            type="text"
                            id="nombre"
                            name="nombre"
                            value={usuario.nombre}
                            onChange={handleInputChange}
                            className="main-input"
                          />
                        </div>
                        <div className="grow">
                          <label htmlFor="" className="">
                            Apellido
                          </label>
                          <input
                            type="text"
                            id="apellido"
                            name="apellido"
                            value={usuario.apellido}
                            onChange={handleInputChange}
                            className="main-input"
                          />
                        </div>
                      </div>
                      <div>
                        <label>Correo Electrónico</label>
                        <input
                          readOnly={true}
                          type="text"
                          value={usuario.email}
                          className="main-input"
                        />
                      </div>
                      <div>
                        <label>Teléfono</label>
                        <input
                          type="number"
                          id="telefono"
                          name="telefono"
                          value={usuario.telefono}
                          onChange={handleInputChange}
                          className="main-input"
                        />
                      </div>
                    </div>

                    <div
                      id="tab-otra"
                      className={`flex flex-col gap-4
                        ${activeTab === "otra" ? "" : "hidden"}`}
                    >
                      <div>
                        <label>País</label>
                        <input
                          type="text"
                          id="pais"
                          name="pais"
                          value={usuario.pais}
                          onChange={handleInputChange}
                          className="main-input"
                        />
                      </div>
                      <div>
                        <label>Provincia</label>
                        <input
                          type="text"
                          id="provincia"
                          name="provincia"
                          value={usuario.provincia}
                          onChange={handleInputChange}
                          className="main-input"
                        />
                      </div>
                      <div>
                        <label>Distrito</label>
                        <input
                          type="text"
                          id="distrito"
                          name="distrito"
                          value={usuario.distrito}
                          onChange={handleInputChange}
                          className="main-input"
                        />
                      </div>
                      <div>
                        <label>Barrio</label>
                        <input
                          type="text"
                          id="barrio"
                          name="barrio"
                          value={usuario.barrio}
                          onChange={handleInputChange}
                          className="main-input"
                        />
                      </div>
                      <div>
                        <label>Dirección</label>
                        <input
                          type="text"
                          id="direccion"
                          name="direccion"
                          value={usuario.direccion}
                          onChange={handleInputChange}
                          className="main-input"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UsuarioView;
