import React, { useState, useEffect } from "react";
import axios from "axios";
import AWS from "aws-sdk";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      fetchImage();
    }
  }, [isOpen]);

  const fetchImage = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/imageModal`,
        {
          headers: {
            "auth-token": `${localStorage.getItem("auth-token")}`,
          },
        }
      );
      if (response.data.length > 0) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${response.data[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url: any) => {
          if (err) {
            console.error(err);
          } else {
            setModalImage(url);
          }
        });
      }
    } catch (err) {
      console.error("Error fetching image:", err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-2xl w-full mx-4">
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div className="mt-4">
          {isLoading && <p>Loading image...</p>}
          {error && <p className="text-red-500">{error}</p>}
          {modalImage && (
            <img
              src={modalImage}
              alt="Modal content"
              className="w-full h-auto"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
