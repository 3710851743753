import axios from "axios";
import { useEffect, useRef, useState } from "react";
import PedidosItemsList from "./ItemsList";
import Close from "../../../../../icons/close";
import Swal from "sweetalert2";
import checkRoles from "../../misc/checkRoles";

interface Pedido {
  pedido_id: number;
  fecha_creacion: string;
  estado: string;
  nombre: string;
  apellido: string;
}

interface Props {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  pedidoView: boolean;
  setPedidoView: React.Dispatch<React.SetStateAction<boolean>>;
  pedidoViewID: number;
}

const PedidoView = ({
  counter,
  setCounter,
  pedidoView,
  setPedidoView,
  pedidoViewID,
}: Props) => {
  const [pedido, setPedido] = useState<Pedido>({} as Pedido);
  const estadoRef = useRef<HTMLSelectElement>(null);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getPedido = async (pedidoViewID: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/pedidos/${pedidoViewID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setPedido(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatus = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/pedidos/update/${pedidoViewID}`,
        {
          estado: `${estadoRef.current?.value}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCounter(counter + 1);
      setPedidoView(!pedidoView);
      Swal.fire({
        icon: "success",
        title: "Estado actualizado",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error al actualizar el estado",
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    if (pedidoView === false) {
      setTimeout(() => {
        setPedido({} as Pedido);
      }, 500);
    } else {
      getPedido(pedidoViewID);
    }
  }, [pedidoView]);

  return (
    <>
      {pedidoView ? (
        <div className={`${pedidoView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white w-screen md:w-1/3 md:min-w-fit min-w-full overflow-scroll px-6 ${
              pedidoView ? "animate-slide-in" : "animate-slide-out"
            }`}
          >
            <div className="w-full flex items-center py-2">
              <div className="grow" />
              <button
                onClick={() => {
                  setPedidoView(!pedidoView);
                }}
              >
                <Close />
              </button>
            </div>
            <div className="grow flex flex-col gap-2 overflow-x-scroll px-2 m-auto w-full">
              <div className="block">
                <label htmlFor="" className="">
                  Pedido ID
                </label>
                <input
                  readOnly={true}
                  type="number"
                  id="nombre"
                  name="nombre"
                  value={pedido.pedido_id}
                  className="main-input"
                />
              </div>
              <div className="block">
                <label htmlFor="" className="">
                  Fecha de pedido
                </label>
                <input
                  readOnly={true}
                  type="date"
                  id="nombre"
                  name="nombre"
                  value={formatDate(pedido.fecha_creacion)}
                  className="main-input"
                />
              </div>
              <div className="block">
                <label htmlFor="" className="">
                  Solicitante
                </label>
                <input
                  readOnly={true}
                  type="string"
                  id="nombre"
                  name="nombre"
                  value={`${pedido.nombre}  ${pedido.apellido}`}
                  className="main-input"
                />
              </div>
              {checkRoles(["Admin"]) && (
                <form action="" onSubmit={changeStatus}>
                  <div className="flex flex-col gap-2">
                    <div className="block">
                      <label htmlFor="estado" className="">
                        Estado
                      </label>
                      <select
                        id="estado"
                        name="estado"
                        ref={estadoRef}
                        disabled={
                          pedido.estado === "Aprobado" ||
                          pedido.estado === "Entregado"
                        }
                        className="main-dropdown "
                      >
                        <option
                          value="Aprobado"
                          selected={pedido.estado === "Aprobado"}
                        >
                          Aprobado
                        </option>
                        <option
                          value="En camino"
                          selected={pedido.estado === "En camino"}
                        >
                          En camino
                        </option>
                        <option
                          value="Entregado"
                          selected={pedido.estado === "Entregado"}
                        >
                          Entregado
                        </option>
                      </select>
                    </div>
                    {pedido.estado !== "Aprobado" &&
                      pedido.estado !== "Entregado" && (
                        <button className="cefa-button">Guardar</button>
                      )}
                  </div>
                </form>
              )}
              <PedidosItemsList pedidoViewID={pedidoViewID} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PedidoView;
