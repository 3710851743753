import AWS from "aws-sdk";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ImageUploader from "../../misc/ImageUploader";
import Close from "../../../../../icons/close";
import Swal from "sweetalert2";
import Trash from "../../../../../icons/trash";
import StatusToggle from "../../../../../icons/toggle";
import Save from "../../../../../icons/save";
import Plus from "../../../../../icons/plus";
import Minus from "../../../../../icons/minus";
import checkRoles from "../../misc/checkRoles";

interface Premio {
  premio_id: number;
  nombre: string;
  descripcion: string;
  puntos: number;
  estado: string;
  imagen: string;
  cantidad_disponible: number;
}

interface Props {
  premioView: boolean;
  setPremioView: React.Dispatch<React.SetStateAction<boolean>>;
  premioViewID: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const PremioView = ({
  premioView,
  setPremioView,
  premioViewID,
  setCounter,
}: Props) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [premio, setPremio] = useState<Premio>({} as Premio);
  const [img, setImg] = useState("");
  const formRef = useRef<HTMLFormElement>(null);
  const [premioCounter, setPremioCounter] = useState(0);
  const [isClosing, setIsClosing] = useState(false);

  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });

  const getPremio = async (premioViewID: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/premios/${premioViewID}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setPremio(response.data);
      const paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${response.data.imagen}`,
        Expires: 60 * 60,
      };
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setImg(url);
        }
      });
    } catch (error) {
      if (error === "Error: Request failed with status code 404") {
        console.log("No existe el usuario");
      }
    }
  };

  const deletePremio = async () => {
    try {
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/premios/${premio.premio_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCounter((counter) => counter + 1);
      setPremioView(!premioView);
      Swal.fire({
        icon: "success",
        title: "Eliminado",
        text: "Se ha eliminado el premio",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al activar el premio.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const setStatus = async () => {
    try {
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let tempState = premio.estado === "Activo" ? "Inactivo" : "Activo";
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/premios/state/${premio.premio_id}`,
        { estado: tempState },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setPremio({ ...premio, estado: tempState });
      setCounter((counter) => counter + 1);
      setPremioView(!premioView);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "El premio ha sido activado con éxito.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al activar el premio.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const handleSubmitButton = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let data = {};
      if (selectedFile) {
        data = {
          nombre: premio.nombre,
          descripcion: premio.descripcion,
          puntos: premio.puntos,
          imagen: `premios/${selectedFile?.name}`,
        };
      } else {
        data = {
          nombre: premio.nombre,
          descripcion: premio.descripcion,
          puntos: premio.puntos,
        };
      }

      if (selectedFile) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `premios/${selectedFile?.name}`,
          Body: selectedFile,
        };
        await s3.upload(uploadParams).promise();
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/premios/${premio.premio_id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (premioCounter !== 0) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/premios/updateCantidad/${premio.premio_id}`,
          { cantidad: premioCounter },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }
      setCounter((counter) => counter + 1);
      setPremioView(!premioView);

      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "El premio ha sido activado con éxito.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al activar el premio.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setPremio({ ...premio, [name]: value });
  };

  useEffect(() => {
    if (premioView === false) {
      setTimeout(() => {
        setImg("");
        setPremio({} as Premio);
        setPremioCounter(0);
      }, 500);
    } else {
      getPremio(premioViewID);
    }
  }, [premioView]);

  return (
    <>
      {premioView || isClosing ? (
        <div className={`${premioView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white p-2 w-screen md:w-1/3 md:min-w-fit min-w-full px-6 ${
              isClosing ? "animate-slide-out" : "animate-slide-in"
            }`}
          >
            <div className="w-full sticky top-0 bg-white">
              <div className="w-full flex items-center mt-4">
                <p className="text-right mr-4 text-lg flex flex-row">
                  <strong>{premio.nombre}</strong>
                </p>
                <div className="grow"></div>
                <button
                  onClick={() => {
                    if (premioView) {
                      setIsClosing(true);
                      setTimeout(() => {
                        setPremioView(false);
                        setIsClosing(false);
                      }, 1000);
                    } else {
                      setPremioView(true);
                    }
                  }}
                >
                  <Close />
                </button>
              </div>

              {checkRoles(["Admin"]) && (
                <div className="flex my-4">
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        Swal.fire({
                          title: "¿Estás seguro?",
                          text: "No podrás revertir esta acción",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Sí, eliminar",
                          cancelButtonText: "Cancelar",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deletePremio();
                          }
                        });
                      }}
                      type="button"
                    >
                      <Trash size={24} />
                    </button>
                    <button onClick={handleSubmitButton}>
                      <Save size={36} />
                    </button>
                  </div>
                  <div className="grow"></div>
                  <StatusToggle status={premio.estado} setStatus={setStatus} />
                </div>
              )}
              <form
                ref={formRef}
                action=""
                className="flex flex-col gap-2"
                onSubmit={checkRoles(["Admin"]) ? handleSubmit : undefined}
              >
                <img src={`${img}`} alt="" className="w-44 m-auto" />
                <div className="flex">
                  <div className="flex-1"></div>
                </div>
                {checkRoles(["Admin"]) && (
                  <ImageUploader
                    setSelectedFile={setSelectedFile}
                    inputId="PremioView"
                  />
                )}
                <div className="block">
                  <label htmlFor="" className="">
                    Nombre*
                  </label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    onChange={handleInputChange}
                    defaultValue={premio.nombre}
                    className="main-input"
                  />
                </div>
                <div className="block">
                  <label className="">Descripcíon</label>
                  <textarea
                    id="descripcion"
                    name="descripcion"
                    onChange={handleInputChange}
                    className="main-input h-24 p-2"
                    defaultValue={premio.descripcion}
                  />
                </div>
                <div className="block">
                  <label htmlFor="" className="">
                    Puntos*
                  </label>
                  <input
                    type="number"
                    id="puntos"
                    name="puntos"
                    onChange={handleInputChange}
                    defaultValue={premio.puntos}
                    className="main-input"
                  />
                </div>

                <div className="flex flex-col justify-center items-center w-fit m-auto">
                  <p>Cantidad Disponible</p>
                  <div className="flex gap-2">
                    {checkRoles(["Admin"]) && (
                      <button
                        type="button"
                        onClick={() => {
                          setPremio((prevPremio) => ({
                            ...prevPremio,
                            cantidad_disponible:
                              prevPremio.cantidad_disponible - 1,
                          }));

                          setPremioCounter((prevCounter) => prevCounter - 1);
                        }}
                      >
                        <Minus />
                      </button>
                    )}
                    <input
                      readOnly={true}
                      type="number"
                      id="puntos"
                      name="puntos"
                      value={premio.cantidad_disponible}
                      className="main-input text-center"
                    />
                    {checkRoles(["Admin"]) && (
                      <button
                        type="button"
                        onClick={() => {
                          setPremio((prevPremio) => ({
                            ...prevPremio,
                            cantidad_disponible:
                              prevPremio.cantidad_disponible + 1,
                          }));

                          setPremioCounter((prevCounter) => prevCounter + 1);
                        }}
                      >
                        <Plus />
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PremioView;
