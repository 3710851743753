import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Close from "../../../../../icons/close";
import Trash from "../../../../../icons/trash";
import Save from "../../../../../icons/save";
import StatusToggle from "../../../../../icons/toggle";
import Swal from "sweetalert2";

interface Producto {
  producto_id: number;
  nombre: string;
  descripcion: string;
  estado: string;
  precio: number;
  precio_reto: number;
  precio_boost: number;
  precio_199: number;
  precio_activo: string;
  casa: string;
  articulo: string;
  cantidad_por_caja: number;
}

interface Props {
  productoView: boolean;
  setProductoView: React.Dispatch<React.SetStateAction<boolean>>;
  productoViewID: number;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const ProductoView = ({
  productoView,
  setProductoView,
  productoViewID,
  counter,
  setCounter,
}: Props) => {
  const [producto, setProducto] = useState<Producto>({} as Producto);
  const formRef = useRef<HTMLFormElement>(null);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setProducto({ ...producto, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const data = {
        nombre: producto.nombre,
        descripcion: producto.descripcion,
        precio: Number(producto.precio),
        precio_reto: Number(producto.precio_reto),
        precio_boost: Number(producto.precio_boost),
        precio_199: Number(producto.precio_199),
        precio_activo: producto.precio_activo,
        casa: producto.casa,
        articulo: producto.articulo,
        cantidad_por_caja: Number(producto.cantidad_por_caja),
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/productos/${productoViewID}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCounter((counter) => counter + 1);
      setProductoView(!productoView);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "El producto ha sido actualizada con éxito.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al actualizar el producto.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const setStatus = async () => {
    try {
      let tempState = producto.estado === "Activo" ? "Inactivo" : "Activo";
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/productos/state/${producto.producto_id}`,
        { estado: tempState },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setProducto({ ...producto, estado: tempState });
      setCounter((counter) => counter + 1);
      setProductoView(!productoView);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "El producto ha sido modificado con éxito.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al modificar el producto.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const getProducto = async (productoViewID: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/productos/${productoViewID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setProducto(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmitButton = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };
  const deleteProducto = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/productos/${producto.producto_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCounter((counter) => counter + 1);
      setProductoView(!productoView);
      Swal.fire({
        icon: "success",
        title: "Eliminado",
        text: "Se ha eliminado el producto correctamente.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  useEffect(() => {
    getProducto(productoViewID);
  }, [productoViewID]);

  return (
    <>
      {productoView ? (
        <div className={`${productoView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`overflow-scroll bg-white p-2 w-screen md:w-1/3 md:min-w-fit min-w-full px-6 ${
              productoView ? "animate-slide-in" : "animate-slide-out"
            }`}
          >
            <div className="w-full sticky top-0 bg-white">
              <div className="w-full flex items-center mt-4">
                <p className="text-lg">
                  <strong>{producto.nombre}</strong>
                </p>
                <div className="grow"></div>
                <button
                  onClick={() => {
                    setProductoView(!productoView);
                  }}
                >
                  <Close />
                </button>
              </div>
              <div className="flex my-4">
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "¿Estás seguro?",
                        text: "No podrás revertir esta acción",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Sí, eliminar",
                        cancelButtonText: "Cancelar",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteProducto();
                        }
                      });
                    }}
                    type="button"
                  >
                    <Trash size={24} />
                  </button>
                  <button onClick={handleSubmitButton}>
                    <Save size={36} />
                  </button>
                </div>
                <div className="grow"></div>
                <StatusToggle status={producto.estado} setStatus={setStatus} />
              </div>
            </div>
            <form
              ref={formRef}
              action=""
              className="flex flex-col gap-6"
              onSubmit={handleSubmit}
            >
              {/* Nombre */}
              <div className="block">
                <label htmlFor="" className="">
                  Nombre
                </label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={producto.nombre}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              {/* Descripcion */}
              <div className="block">
                <label className="">Descripcíon</label>
                <textarea
                  id="descripcion"
                  name="descripcion"
                  onChange={handleInputChange}
                  className="main-input h-24 p-2"
                  value={producto.descripcion}
                />
              </div>
              <div className="block">
                <label htmlFor="" className="">
                  Cefamonedas Regular
                </label>
                <input
                  type="number"
                  id="precio"
                  name="precio"
                  value={producto.precio}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div className="block">
                <label htmlFor="" className="">
                  Cefamonedas Reto
                </label>
                <input
                  type="number"
                  id="precio_reto"
                  name="precio_reto"
                  value={producto.precio_reto}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div className="block">
                <label htmlFor="" className="">
                  Cefamonedas 199
                </label>
                <input
                  type="number"
                  id="precio_199"
                  name="precio_199"
                  value={producto.precio_199}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div className="block">
                <label htmlFor="" className="">
                  Cefamonedas Boost
                </label>
                <input
                  type="number"
                  id="precio_boost"
                  name="precio_boost"
                  value={producto.precio_boost}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div>
                <label>Precio Activo:</label>
                <select
                  id="plan"
                  name="precio_activo"
                  className="main-dropdown"
                  value={producto.precio_activo}
                  onChange={handleInputChange}
                >
                  <option value="regular">Regular</option>
                  <option value="199">199</option>
                  <option value="boost">Boost</option>
                  <option value="reto">Reto</option>
                </select>
              </div>
              <div>
                <label>Cantidad Por Caja*</label>
                <input
                  type="number"
                  id="cantidad_por_caja"
                  name="cantidad_por_caja"
                  value={producto.cantidad_por_caja}
                  onChange={handleInputChange}
                  className="main-input"
                  required
                />
              </div>
              {/* Articulo */}
              <div className="block">
                <label htmlFor="" className="">
                  Articulo
                </label>
                <input
                  type="text"
                  id="articulo"
                  name="articulo"
                  value={producto.articulo}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              {/* Casa */}
              <div className="block">
                <label htmlFor="" className="">
                  Casa
                </label>
                <input
                  type="text"
                  id="casa"
                  name="casa"
                  value={producto.casa}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductoView;
